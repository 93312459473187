<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Create Write Off Parameters">

            <div class="vx-row mb-12">

                <div class="vx-col w-full ">
                    <div class="vx-row mb-2">
                        <div class="vx-col w-1/2">
                            <vs-button type="line" icon-pack="feather" color="Red" icon="icon-arrow-left" v-on:click.stop="
                                handleBack()" />
                        </div>
                        <div class="vx-col w-1/2">

                        </div>
                    </div>

                    <div class="vx-row mb-2">
                        <!-- <div class="vx-col w-1/2">
                            <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Territory</label>
        <multiselect
          class="selectExample"
          v-model="selectedterritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
                        </div> -->
                        <div class="vx-col w-1/2">
                            <label class="vs-input--label">Customer Category</label>
                            <multiselect class="selectExample" v-model="select.distChannel" :options="option.distChannel"
                                :multiple="false" :allow-empty="true" :group-select="false" :max-height="160"
                                :limit="4" :loading="loading.distChannel" placeholder=" Type to search" track-by="ID"
                                label="name">                            
                            </multiselect>
                        </div>
                        <div class="vx-col w-1/2">
                            <label class="vs-input--label">&nbsp;</label>
                            <vs-button
                                class="mr-2"
                                v-on:click="handleAddSetting()"
                                color="success"
                                type="border"
                                icon-pack="feather"
                                icon="icon-plus"
                            ></vs-button>
                        </div>
                    </div>  
                    <div class="vx-row mb-2" v-for="(tr, tr_index) in arrAgingFrom" v-bind:key="tr_index">
                        <div class="vx-col w-2/12">
                            <label class="vs-input--label">Aging From</label>
                            <vs-input class="w-full" type="number" v-model="arrAgingFrom[tr_index]" />
                        </div>
                        <div class="vx-col w-2/12">
                            <label class="vs-input--label">Aging To</label>
                            <vs-input class="w-full" type="number" v-model="arrAgingTo[tr_index]" />
                        </div>
                        <div class="vx-col w-3/12">
                            <label class="vs-input--label">% Provision</label>
                            <vs-input class="w-full" type="number" v-model="arrProvision[tr_index]" />
                        </div>
                        <div class="vx-col w-3/12">
                            <label class="vs-input--label">% Expense</label>
                            <vs-input class="w-full" type="number" v-model="arrExpense[tr_index]" />
                        </div>
                        <div class="vx-col w-1/12">
                            <label class="vs-input--label">&nbsp;</label>
                            <vs-button
                                class="mr-2"
                                v-on:click="handleDelSetting(tr_index)"
                                color="danger"
                                type="border"
                                icon-pack="feather"
                                icon="icon-trash"
                            ></vs-button>
                        </div>
                    </div>  
                    <br>
                    <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
                </div>

                
          
            </div>
        
        </vx-card>
    </div>
</template>

<script>
// import DataForm from "./form";
export default {
    components: {
        // DataForm
    },
    data(){
      return {
        detailShow: "core vx-col md:w-1/2 w-full mb-base ",
        detailHide: "core vx-col md:w-1/1 w-full mb-base ",
        mutualAction: "",
        detail: false,
        deleteId: null,
        customerId: null,
        table: {
          data: [],
          length: 10,
          page: 1,
          search: "",
          order: "deleted_at",
          sort: "desc",
          total: 0,
          totalPage: 0,
          totalSearch: 0,
          limits: [10, 25, 50, 100, "All"],
          start: 1,
          end: 0
        },
        selectedterritory:null,
        optionTerritory: [],
        // createAgingFrom: null,
        // createAgingTo: null,
        // createExpense: null,
        // createProvision: null,
        arrAgingFrom: [],
        arrAgingTo: [],
        arrExpense: [],
        arrProvision: [],
        option: {
            distChannel: [],
        },
        loading: {
            distChannel: false,
        },
        counterLoading: {
            distChannel: 0,
        },
        select: {
            distChannel: null,
        },
      };
    },
    mounted() {
        this.getOptionDistChannel();
        this.getOptionTerritory();        
    },
    
    methods: {
        handleBack() {
            this.$router.push("write-off-parameters");
        },
        handleAddSetting(){
            this.arrAgingFrom.push('')
            this.arrAgingTo.push('')
            this.arrProvision.push('')
            this.arrExpense.push('')
        },
        handleDelSetting(i){
            this.arrAgingFrom = this.arrAgingFrom.filter((v, j) => { return j != i; })
            this.arrAgingTo = this.arrAgingTo.filter((v, j) => { return j != i; })
            this.arrProvision = this.arrProvision.filter((v, j) => { return j != i; })
            this.arrExpense = this.arrExpense.filter((v, j) => { return j != i; })
        },
        handleSubmit() {
            // console.log(this.arrAgingFrom);
            // console.log(this.arrAgingTo);
            // console.log(this.arrExpense);
            // console.log(this.arrProvision);
            // let territory = this.selectedterritory;
            let customer_category = this.select.distChannel;
            let total = 100;
            // let aging_from = parseInt(this.createAgingFrom);
            // let aging_to = parseInt(this.createAgingTo);
            // let provision = parseFloat(this.createProvision);
            // let expense = parseFloat(this.createExpense);

            let lines = {
                aging_from : this.arrAgingFrom,
                aging_to : this.arrAgingTo,
                provision : this.arrProvision,
                expense : this.arrExpense,
            }
            // if (territory == null ) {
            //     this.$vs.notify({
            //         color: "danger",
            //         title: "error",
            //         text: "Territory is required",
            //         position: "top-right",
            //         iconPack: "feather",
            //         icon: "icon-x-circle",
            //     });
            //     return false;
            // }
             if (customer_category == null ) {
                this.$vs.notify({
                    color: "danger",
                    title: "Warning",
                    text: "Customer category is required",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }
            if (this.arrAgingFrom.length == 0 || this.arrAgingTo.length == 0 || this.arrProvision.length == 0 || this.arrExpense.length == 0) {
                this.$vs.notify({
                    color: "danger",
                    title: "Warning",
                    text: "Please add at least 1 setting",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                return false;
            }else{
                let isTrueAgingFT = true, isTruePE = true;
                let isAgingNotEmpty = true, isPE_not_empty = true;

                for (let i = 0; i < this.arrAgingFrom.length; i++) {
                    let aging_from = this.arrAgingFrom[i];
                    let aging_to = this.arrAgingTo[i];
                    let provision = this.arrProvision[i];
                    let expense = this.arrExpense[i];

                    // Check is true Aging From <= aging To
                    if (parseInt(aging_from) >= parseInt(aging_to)) {
                        isTrueAgingFT = false;
                    }

                    // Check if aging from or aging to != '' (empty string)
                    if (aging_from === '' || aging_to === '') {
                        isAgingNotEmpty = false;
                    }

                    // Check is provision + expense == 100 ?
                    if (parseInt(provision) + parseInt(expense) != total) {
                        isTruePE = false;
                    }

                    // Check if prov or exp == '' (empty string)
                    if (provision === '' || expense === '') {
                        isPE_not_empty = false;
                    }
                                        
                }

                // Check is true Aging From <= aging To
                if (!isTrueAgingFT) {
                    this.$vs.notify({
                        color: "danger",
                        title: "error",
                        text: "Aging From must be lower than Aging To",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                if (!isAgingNotEmpty) {
                    this.$vs.notify({
                        color: "danger",
                        title: "error",
                        text: "Aging From & Aging To is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                if (!isTruePE) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Warning",
                        text: "Provision + Expense must be 100",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }

                if (!isPE_not_empty) {
                    this.$vs.notify({
                        color: "danger",
                        title: "Warning",
                        text: "Provision & Expense is required",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-x-circle",
                    });
                    return false;
                }
            }
            
            // console.log("handleSubmit");
            let param = {
                customer_category_id : this.select.distChannel.ID,
                setting_lines : lines,                
            }
            // console.log("______param________");
            // console.log(param);
            // console.log(this.select.distChannel);

            this.$http
                .post("/api/v1/setting/write-off-parameters/create", param)
                .then((resp) => {
                    // console.log(resp)
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "Success Created Data",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Gagal!",
                            text: "Failed Created Data",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-check",
                        });
                    }
                    
                // console.log(resp);
                this.$nextTick(() => {
                    this.$emit("closeDetail", true);
                    this.$vs.loading.close();
                    this.handleBack()
                });
        }).catch((e) => {
                this.$vs.notify({
                    color: "danger",
                    title: "error catch",
                    text: "error catch",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.handleBack()
            });
        },
        getData() {
            this.$vs.loading();
            this.$http
            .get("/api/v1/setting/write-off-parameters", {
            // .get("/api/v1/master/work-team", {
                params: {
                    length: this.table.length,
                    page: this.table.page,
                    search: this.table.search,
                    order: this.table.order,
                    sort: this.table.sort
                }
            })
            .then(resp => {
                // console.log("respon");
                // console.log(resp);
                if (resp.status == "success") {
                this.table.total = resp.data.total_record;
                this.table.totalPage = resp.data.total_page;
                this.table.totalSearch = resp.data.total_record_search;
                this.table.length = resp.data.total_record_per_page;
                this.table.data = resp.data.records;
                this.setStartEnd();
                this.$vs.loading.close();
                } else {
                console.log(resp.message);
                }
            });
        },
        getOptionDistChannel(query) {            
            let param = {
                search: query,
            };
            // console.log(param)

            this.loading.distChannel = true
            this.counterLoading.distChannel++
            this.$http.get('/api/v1/write-off/distribution-channel', {
                params: param,
            }).then((r) => {
                // console.log(r);
                this.option.distChannel = r.data.distributionChannel

                this.counterLoading.distChannel--
                if (this.counterLoading.distChannel == 0) {
                    this.loading.distChannel = false
                }
                // console.log(r)
            }).catch((e) => {
                console.log(e)
            })
        },
        getOptionTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionTerritory = resp.data.records;
            // console.log(resp.data.records)
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Territory option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
            valStart = 1;
            }
            if (this.table.total == 0) {
            valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
            valEnd = this.table.total;
            }
            if (this.table.totalSearch < this.table.total) {
            valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleEdit(val) {
            // this.$router.push("/setting/user/edit/" + val);
        },
        handleDelete(val) {
            this.deleteId = val;
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Please confirm to delete this data",
                accept: this.acceptDelete
            });
        },
        acceptDelete() {
            this.$vs.loading();
            this.$http
                .delete("/api/v1/setting/user-personal/" + this.deleteId)
                .then(resp => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "The user data was successfully deleted",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                    this.getData();
                })
                .catch(error => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        create() {
            this.$router.push("write-off-parameters-create");

        },
        handleClose() {
            window.scrollTo(0, 0);
            this.detail = false;
            this.mutualAction = "";
            // console.log("handleClose", this.detail)
        },
        setAction(val) {
            this.mutualAction = val;
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            }
        }
    }
}
</script>